import React, { useRef, useState } from "react";
import styles from "./index.module.scss";
import assets from "../../assets";
import { useTranslation } from "react-i18next";

function Video({ src, autoplay, muted }) {
  // STATES
  const [play, setPlay] = useState(false);
  const videoRef = useRef(null);
  // STORE STATES
  // HOOKS
  const { t } = useTranslation();
  // METHODS
  const playPause = (e) => {
    e.stopPropagation();
    if (videoRef.current.paused) {
      videoRef.current.play();
      setPlay(true);
    } else {
      videoRef.current.pause();
      setPlay(false);
    }
  };
  // EFFECTS
  // RENDER
  return (
    <>
      {src && (
        <div className={styles.videoContainer}>
          <video
            ref={videoRef}
            className={styles.video}
            loop={true}
            muted={muted || true}
            autoPlay={autoplay || false}
          >
            <source src={src} type="video/mp4" />
          </video>
          <div onClick={playPause} className={styles.videoControls}>
            {!play && (
              <div className={styles.videoControlsContainer}>
                <button onClick={playPause} className={styles.videoPlayBtn}>
                  <img
                    loading="lazy"
                    src={assets.icons.playIcon}
                    alt="play icon"
                  />
                </button>
                <p className={styles.videoText}>{t("watch_video")}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default Video;
