import React, { useState } from "react";
import Slider from "react-slick";
import styles from "./index.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
import { useParams } from "react-router-dom";

function LogoSlider({ slides }) {
	const [sliderRef, setSliderRef] = useState(null);
	const [activeIndex, setActiveIndex] = useState(0);
	const { locale } = useParams();

	const sliderSettings = {
		arrows: false,
		infinite: true,
		autoplay: true,
		centerMode: true,
		slidesToShow: slides.length < 6 ? slides.length : 5,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				},
			},
		],
		dots: true,
		speed: 500,
		autoplaySpeed: 2000,
		rtl: locale === "ar" ? true : false,
		beforeChange: (oldIndex, newIndex) => {
			setActiveIndex(newIndex);
		},
		appendDots: (dots) => (
			<ul>
				{dots.map((dot, index) => (
					<li
						onClick={() => {
							setActiveIndex(index);
							sliderRef.slickGoTo(index);
						}}
						className={activeIndex === index ? "slick-active" : undefined}
						key={index}
					></li>
				))}
			</ul>
		),
	};

	return (
		<section className={styles.carousel}>
			<Slider ref={setSliderRef} {...sliderSettings}>
				{slides.map((slide, index) => (
					<a key={index} className={styles.carouselSlideLink} href={slide.link} target="_blank">
						<img width={100} height={100} className={styles.carouselSlideImage} src={slide.image} loading="lazy" alt={slide.company} />
					</a>
				))}
			</Slider>
		</section>
	);
}
export default LogoSlider;
