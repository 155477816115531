import hero from "./homehero.png";
import aboutUs from "./about_us.png";
import approuch from "./approuch.png";
import careersImg from "./careers-image.png";
import jobDetailsHeader from "./job-details-min.png";
import notFound from "./404.jpg";

const images = {
  hero,
  aboutUs,
  approuch,
  careersImg,
  jobDetailsHeader,
  notFound,
};

export default images;
