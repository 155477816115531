import styles from "./index.module.css";
import Carousel from "../../components/Carousel";
import AboutUs from "../../components/AboutUs";
import Explore from "../../components/Explore";
import Information from "../../components/Information";
import WhyUs from "../../components/WhyUs";
import Companies from "../../components/Companies";
import { getHome } from "../../utils/getHome";
import Partners from "../../components/Partners";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import LoadingLogo from "../../components/LoadingLogo";
import SEO from "../../components/SEO";

export default function HomePage() {
  const { t, i18n } = useTranslation();
  const [home, setHome] = useState(null);
  const [loading, setLoading] = useState(true);
  const { locale } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();

  useEffect(() => {
    setLoading(true);
    if (locale) i18n.changeLanguage(locale);
    async function startFetch() {
      setHome(null);
      const res = await getHome(locale);
      setHome(res);
      setLoading(false);
    }
    startFetch();
  }, [locale]);

  useEffect(() => {
    if (location.hash === "#explore") {
      ref.current?.scrollIntoView({ behavior: "smooth" });
      navigate(`/${locale}`);
    }
  }, [location, ref?.current]);
  console.log('homeeee', home)

  return (
    <>
      <SEO title={"HIG - Home"} description={""} name={""} type={""} />
      {loading && !home ? (
        <LoadingLogo />
      ) : (
        <>
          <Navbar animate={true} companies={home.companies} />
          <main className={styles.main}>
            <section className={styles.hero}>
              <Carousel slides={home.main_section} />
              <Explore text={t("homePage.explore")} />
            </section>
            <section className={styles.aboutUs}>
              <AboutUs about={home.about_us} />
            </section>
            <section className={styles.info}>
              <Information info={home.hig_info} />
            </section>
            <section className={styles.whyUs}>
              <WhyUs why={home.why_us} />
            </section>
            <section ref={ref} id="#explore" className={styles.companies}>
              <Companies companies={home.our_companies} />
            </section>
            <section className={styles.partners}>
              <Partners partners={home.partners} />
            </section>
          </main>
          <Footer data={home.company_info[0]} />
        </>
      )}
    </>
  );
}
