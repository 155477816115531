import React from "react";
import styles from "./index.module.scss";

function Logo({ textColor, logoColor }) {
  // STATES
  // STORE STATES
  // HOOKS
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <svg
      className={styles.logo}
      width="195"
      height="62"
      viewBox="0 0 195 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="logo"
        d="M14.0822 8.08956C14.0369 5.94962 13.8555 4.49264 13.538 3.67309C13.1752 2.85354 12.767 2.30717 12.2682 1.94293C11.7693 1.53315 11.4972 1.03232 11.4972 0.349359L29.2749 0.167236L29.5923 28.4873L51.2702 28.2596L50.9981 4.26499L63.8779 4.1284L64.4221 53.2104L51.5877 60.814L51.2702 30.6272L29.5923 30.8549L29.8191 53.5746L14.6264 61.2238L14.0822 8.08956Z"
        fill={logoColor || "#912934"}
      />
      <path
        className="logo"
        d="M51.2713 30.5817L27.3258 38.6861L27.2351 30.8548L51.2713 30.5817Z"
        fill={logoColor || "#912934"}
      />
      <path
        className="logo"
        d="M40.3413 10.4572L53.2211 10.3206L63.8786 4.12842L50.9988 4.26501L40.3413 10.4572Z"
        fill={logoColor || "#912934"}
      />
      <path
        className="logo"
        d="M0.794922 6.54152L18.618 6.3594L29.2755 0.167236L11.4525 0.349359L0.794922 6.54152Z"
        fill={logoColor || "#912934"}
      />
      <path
        className="text"
        d="M85.0569 4.31042V19.0168H82.9708V12.4604H74.5808V19.0168H72.4946V4.31042H74.5808V10.6392H82.9708V4.31042H85.0569Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M101.61 15.3744H93.8095L92.1769 19.0623H90L96.6213 4.35596H98.7074L105.374 19.0623H103.197L101.61 15.3744ZM100.884 13.6897L97.7097 6.4959L94.5351 13.6897H100.884Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M123.016 15.0556C123.016 17.5598 121.112 19.0168 117.393 19.0168H110.409V4.31042H116.985C120.341 4.31042 122.245 5.72187 122.245 8.08947C122.245 9.68304 121.429 10.8213 120.295 11.3677C121.928 11.8685 123.016 13.0978 123.016 15.0556ZM112.54 6.04059V10.7302H116.849C118.98 10.7302 120.205 9.91069 120.205 8.36265C120.205 6.81461 118.98 5.99506 116.849 5.99506H112.54V6.04059ZM120.93 14.8735C120.93 13.1889 119.66 12.4149 117.348 12.4149H112.54V17.2866H117.348C119.66 17.3322 120.93 16.6037 120.93 14.8735Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M138.436 15.3744H130.635L129.002 19.0623H126.871L133.492 4.35596H135.578L142.245 19.0623H140.023L138.436 15.3744ZM137.71 13.6897L134.535 6.4959L131.361 13.6897H137.71Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M145.873 17.3322L146.644 15.6931C147.732 16.6947 149.592 17.3777 151.496 17.3777C154.036 17.3777 155.125 16.376 155.125 15.1012C155.125 11.5043 146.236 13.7808 146.236 8.31711C146.236 6.04058 148.004 4.1283 151.859 4.1283C153.583 4.1283 155.351 4.5836 156.576 5.44868L155.896 7.13332C154.626 6.31377 153.174 5.94952 151.859 5.94952C149.365 5.94952 148.322 6.99672 148.322 8.27158C148.322 11.8685 157.211 9.6375 157.211 15.0101C157.211 17.2411 155.397 19.1989 151.542 19.1989C149.274 19.1989 147.052 18.4249 145.873 17.3322Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M175.623 4.31042V19.0168H173.537V12.4604H165.147V19.0168H163.061V4.31042H165.147V10.6392H173.537V4.31042H175.623Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M188.457 13.9629V19.0623H186.371V13.9174L180.566 4.35596H182.788L187.459 12.0962L192.176 4.35596H194.262L188.457 13.9629Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M72.407 24.5717H75.2188V36.7739H72.407V24.5717Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M89.7312 24.5717V36.7739H87.4183L81.3413 29.3524V36.7739H78.5748V24.5717H80.8877L86.9648 31.9476V24.5717H89.7312Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M104.788 24.5717L99.5268 36.7739H96.7604L91.4996 24.5717H94.5382L98.257 33.3135L102.021 24.5717H104.788Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M115.718 34.4973V36.7739H106.285V24.5717H115.491V26.8482H109.096V29.489H114.765V31.6744H109.096V34.4973H115.718Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M117.486 35.5446L118.438 33.4047C119.481 34.1332 120.978 34.6796 122.474 34.6796C124.152 34.6796 124.833 34.1332 124.833 33.3592C124.833 31.0826 117.758 32.6307 117.758 28.1232C117.758 26.0287 119.436 24.2986 122.883 24.2986C124.424 24.2986 125.966 24.6628 127.1 25.3913L126.239 27.5313C125.105 26.8938 123.926 26.5751 122.883 26.5751C121.205 26.5751 120.57 27.2125 120.57 27.9866C120.57 30.2176 127.644 28.6695 127.644 33.1771C127.644 35.2259 125.966 36.9561 122.474 36.9561C120.524 36.9561 118.574 36.4097 117.486 35.5446Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M132.407 26.8484H128.507V24.5264H139.119V26.8484H135.219V36.7741H132.407V26.8484Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M152.178 36.7737V29.4433L148.595 35.4989H147.325L143.742 29.6254V36.8193H141.112V24.5715H143.425L147.96 32.1751L152.45 24.5715H154.763L154.808 36.7737H152.178Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M167.643 34.4972V36.7737H158.209V24.5715H167.416V26.8481H161.021V29.4888H166.69V31.6743H161.021V34.4972H167.643Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M181.565 24.5715V36.7737H179.252L173.175 29.3522V36.7737H170.409V24.5715H172.722L178.753 31.993V24.5715H181.565Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M187.46 26.8482H183.56V24.5261H194.172V26.8482H190.272V36.7739H187.46V26.8482Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M81.4288 46.745H84.0138V51.7079C82.6533 52.7095 80.8393 53.2559 79.1159 53.2559C75.3064 53.2559 72.4946 50.6151 72.4946 46.9271C72.4946 43.2392 75.3064 40.5984 79.2066 40.5984C81.3381 40.5984 83.1068 41.3269 84.2859 42.6928L82.4719 44.3774C81.5649 43.4213 80.5671 43.0115 79.3427 43.0115C76.9844 43.0115 75.3518 44.6051 75.3518 46.9271C75.3518 49.2037 76.9844 50.8428 79.2973 50.8428C80.0683 50.8428 80.7939 50.7062 81.4742 50.3419V46.745H81.4288Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M96.4847 53.0738L94.1264 49.659H93.9904H91.5414V53.0738H88.7296V40.826H93.9904C97.2556 40.826 99.2965 42.5107 99.2965 45.2425C99.2965 47.0637 98.3894 48.4297 96.8021 49.1126L99.5232 53.0283H96.4847V53.0738ZM93.809 43.1481H91.496V47.428H93.809C95.5323 47.428 96.394 46.6084 96.394 45.288C96.394 43.9221 95.5323 43.1481 93.809 43.1481Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M102.835 46.9271C102.835 43.2847 105.647 40.5984 109.502 40.5984C113.311 40.5984 116.168 43.2847 116.168 46.9271C116.168 50.5696 113.356 53.2559 109.502 53.2559C105.647 53.2559 102.835 50.5696 102.835 46.9271ZM113.266 46.9271C113.266 44.6051 111.633 43.0115 109.456 43.0115C107.279 43.0115 105.647 44.6051 105.647 46.9271C105.647 49.2492 107.279 50.8428 109.456 50.8428C111.633 50.8428 113.266 49.2492 113.266 46.9271Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M120.25 47.7012V40.8716H123.061V47.6101C123.061 49.9322 124.059 50.8883 125.782 50.8883C127.506 50.8883 128.504 49.9322 128.504 47.6101V40.826H131.27V47.6556C131.27 51.2526 129.229 53.2559 125.737 53.2559C122.29 53.2559 120.25 51.2981 120.25 47.7012Z"
        fill={textColor || "#FFF"}
      />
      <path
        className="text"
        d="M146.689 45.288C146.689 48.0199 144.648 49.7045 141.383 49.7045H138.934V53.0738H136.122V40.826H141.383C144.648 40.826 146.689 42.5107 146.689 45.288ZM143.832 45.288C143.832 43.9221 142.97 43.1481 141.247 43.1481H138.934V47.3825H141.247C142.97 47.3825 143.832 46.6084 143.832 45.288Z"
        fill={textColor || "#FFF"}
      />
    </svg>
  );
}
export default Logo;
