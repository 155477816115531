export const getCareers = async (locale) => {
  const res = await fetch(`${process.env.REACT_APP_BASE_URL}/career_page`, {
    headers: {
      "Accept-Language": locale,
    },
  });
  return res.json();
};

export const getJobs = async (locale, page) => {
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}/jobs?page=${page}`,
    {
      headers: {
        "Accept-Language": locale,
      },
    }
  );
  return res.json();
};
