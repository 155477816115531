import React, { useState, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import { gsap } from "gsap";
import LanguageSwitcher from "../../LanguageSwitcher";

import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function HamburgerMenu({ props }) {
  // VARIABLES
  const tabs = [
    {
      title: "home",
      link: "/",
    },
    {
      title: "about",
      link: "/about",
    },
    {
      title: "companies",
      link: "#",
      list:[
        {
        id:1,
        name:'HIG Steel',
        link:'https://steel.alhabashy.com/'
      },
      {
        id:1,
        name:'HIG Contracting',
        link:'https://contracting.alhabashy.com/'
      }

    ]
    },
    {
      title: "careers",
      link: "/careers",
    },
    {
      title: "contact",
      link: "/contact",
    },
  ];

  const socialMedia = [
    {
      type: "facebook_dark",
      link: "https://web.facebook.com/Alhabashy.Group?mibextid=ZbWKwL&_rdc=1&_rdr",
    },
    {
      type: "linkedin_dark",
      link: "https://www.linkedin.com/company/alhabashy-group",
    },
    {
      type: "instagram_dark",
      link: "https://www.instagram.com/alhabashy_group/",
    },
  ];
  // STATES
  const [toogleMenu, setToogleMenu] = useState(false);
  // STORE STATES
  // HOOKS
  const tlRef = useRef(null);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  // METHODS
  const handleTimeline = () => {
    tlRef.current.play();
  };
  const handleReverseTimeline = () => {
    tlRef.current.reverse();
  };
  const toogle = () => {
    if (!toogleMenu) {
      handleTimeline();
      disableScroll();
    } else {
      handleReverseTimeline();
      enableScroll();
    }
    setToogleMenu((prev) => !prev);
  };

  function disableScroll() {
    const scrollTop = window.screenTop || document.documentElement.scrollTop;
    const scrollLeft = window.screenLeft || document.documentElement.scrollLeft;

    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  }

  function enableScroll() {
    window.onscroll = function () {};
  }
  // EFFECTS
  useEffect(() => {
    const timeline = gsap.timeline({ paused: true });
    timeline.to(".text", { fill: "#A7A9AC", duration: 0.35 });
    tlRef.current = timeline;
    enableScroll();
    return () => {
      tlRef.current = null;
      enableScroll();
      setToogleMenu(false);
    };
  }, []);
  // RENDER
  return (
    <>
      <div onClick={toogle} className={styles.hamburgerMenu}>
        <p
          className={`${styles.line} ${
            toogleMenu ? styles.lineHide : undefined
          }`}
        ></p>
      </div>
      <div
        className={`${styles.responsiveMenu} ${
          toogleMenu ? styles.responsiveMenuAnimation : undefined
        }`}
      >
        <ul className={styles.responsiveMenuList}>
          {tabs.map((tab, index) => (
            <li
              key={index}
              onClick={() => navigate(`/${i18n.language}${tab.link}`)}
              className={styles.responsiveMenuListItem}
            >
              <a>{t(tab.title)}</a>
              {tab.list &&
              <ul className={styles.submenu}>
                {tab.list.map((item, index)=>(
                  <li><a target="_blank" href={item.link}>{item.name}</a></li>
                ))}
              </ul>
}

            </li>
          ))}
          <LanguageSwitcher />
          <div className={styles.socialMedia}>
            {socialMedia.map((social, index) => {
              return (
                <a
                  href={social.link}
                  className={styles.socialMediaLink}
                  key={index}
                >
                  <img
                    loading="lazy"
                    alt={social.type}
                    className={styles.socialMediaLinkImage}
                    src={assets.icons[social.type]}
                  />
                </a>
              );
            })}
          </div>
        </ul>
      </div>
    </>
  );
}
export default HamburgerMenu;
