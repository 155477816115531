import React, { useEffect, useRef } from "react";
import ArrowCircle from "../ArrowCircle";
import styles from "./index.module.scss";
import { gsap } from "gsap";

function MoreAboutUs({ text, link, circleColor, arrowColor }) {
  // STATES
  // STORE STATES
  // HOOKS
  const tlRef = useRef(null);
  // METHODS
  // EFFECTS
  useEffect(() => {
    const tl = gsap.timeline();
    tl.to("#arrow-circle", { x: 25, repeat: -1, yoyoEase: true, duration: 1 });
    tlRef.current = tl;
  }, []);
  // RENDER
  return (
    <a className={styles.moreAboutUs} href={link}>
      <ArrowCircle
        animate={true}
        circleColor={circleColor}
        arrowColor={arrowColor}
      />
      <p className={styles.moreAboutUsText}>{text}</p>
    </a>
  );
}
export default MoreAboutUs;
