import styles from "./index.module.scss";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { getContact } from "../../utils/getContact";
import assets from "../../assets";
import FormSection from "../../components/FormSection";
import { useEffect, useState } from "react";
import LoadingLogo from "../../components/LoadingLogo";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";



const fields = [
  {
    fieldName: "first_name",
    label: "first_name",
    type: "text",
    validations: {
      required: "required",
      minLength: { value: 2, message: "too_short" },
      maxLength: {
        value: 30,
        message: "exceed",
      },
    },
  },
  {
    fieldName: "last_name",
    label: "last_name",
    type: "text",
    validations: {
      required: "required",
      minLength: { value: 2, message: "too_short" },
      maxLength: {
        value: 30,
        message: "exceed",
      },
    },
  },
  {
    fieldName: "email",
    label: "email",
    type: "email",
    validations: {
      required: "required",
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: "invalid_email",
      },
      maxLength: {
        value: 100,
        message: "exceed",
      },
    },
  },
  {
    fieldName: "phone",
    label: "phone_number",
    type: "number",
    validations: {
      required: "required",
      pattern: {
        value: /^(01)[0-9]{9}$/,
        message: "invalid_phone",
      },
      maxLength: {
        value: 11,
        message: "exceed",
      },
    },
  },
  {
    fieldName: "message",
    label: "message",
    type: "textarea",
    fullWidth: true,
    validations: {
      maxLength: {
        value: 1000,
        message: "exceed",
      },
    },
  },
];

export default function ContactPage() {
  const [contact, setContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const { locale } = useParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLoading(true);
    if (locale) i18n.changeLanguage(locale);
    async function startFetch() {
      setContact(null);
      const contactData = await getContact(locale);
      setContact(contactData);
      setLoading(false);
    }
    startFetch();
  }, [locale]);

  const socialMedia = [
    {
      type: "facebook",
      link: contact.facebook,
    },
    {
      type: "linkedin",
      link: contact.linkedin,
    },
    {
      type: "instagram",
      link: contact.instagram,
    },
  ];
  return (
    <>
      {loading && !contact ? (
        <LoadingLogo />
      ) : (
        <>
          <Navbar animate={false} dark={true} />
          <main className={styles.contactUs}>
            <div className={styles.contactUsMapContentContainer}>
              <div className={styles.contactUsMapContent}>
                <h1 className={styles.contactUsHeader}>
                  {t("contactPage.header")}
                </h1>
                <section className={styles.contactUsSeeUs}>
                  <h2 className={styles.contactUsSeeUsHeader}>
                    {t("contactPage.come_see_us")}
                  </h2>
                  <p className={styles.contactUsSeeUsBody}>
                    {contact.company_info[0].address}
                  </p>
                </section>
                <section className={styles.contactUsGetInTouch}>
                  <h2 className={styles.contactUsGetInTouchHeader}>
                    {t("get_in_touch")}
                  </h2>
                  <p className={styles.contactUsGetInTouchPhone}>
                    {t("cell_phone")} : {contact.company_info[0].phone2}
                  </p>
                  <p className={styles.contactUsGetInTouchLand}>
                    {t("land_line")} : {contact.company_info[0].phone}
                  </p>
                  <p className={styles.contactUsGetInTouchEmail}>
                    {t("contactPage.email")} : {contact.company_info[0].email}
                  </p>
                </section>
                <section className={styles.contactUsFollow}>
                  <h2 className={styles.contactUsFollowHeader}>
                    {t("follow_us")}
                  </h2>
                  {socialMedia.map((social, index) => {
                    return (
                      <a
                        className={styles.socialIcon}
                        href={social.link}
                        key={index}
                      >
                        <img
                          loading="lazy"
                          alt={social.type}
                          src={assets.icons[social.type]}
                        />
                      </a>
                    );
                  })}
                </section>
              </div>
              <iframe
                title="googlemap"
                className={styles.contactUsMap}
                src="https://snazzymaps.com/embed/512395"
                width="100%"
                height="700px"
              ></iframe>
            </div>
            <FormSection
              content={{
                title: contact.contact_us_page.contact_us_title,
                description: contact.contact_us_page.contact_us_description,
              }}
              formFields={fields}
              page={"contact"}
            />
          </main>
          <Footer data={contact.company_info[0]} />
        </>
      )}
    </>
  );
}
