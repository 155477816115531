import { useTranslation } from "react-i18next";
import CompanyCard from "./CompanyCard";
import styles from "./index.module.scss";

function Companies({ companies }) {
  // STATES
  // STORE STATES
  // HOOKS
  const { t } = useTranslation();
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <div className={styles.companies}>
      <p className={styles.companiesText}>{t("the_group")}</p>
      <h2 className={styles.companiesHeader}>
        {t("homePage.explore_companies")}
      </h2>
      <div className={styles.companiesCardContainer}>
        {companies.map((company, index) => (
          <CompanyCard company={company} key={index} />
        ))}
      </div>
    </div>
  );
}
export default Companies;
