import { useTranslation } from "react-i18next";
import LogoSlider from "../LogoSlider";
import styles from "./index.module.scss";

function Partners({ partners }) {
  // STATES
  // STORE STATES
  // HOOKS
  const { t } = useTranslation();
  // METHODS
  // EFFECTS
  // RENDER
  const { title, body, companies } = partners;

  return (
    <div className={styles.partners}>
      <p className={styles.partnersText}>{t("our_partners")}</p>
      <div className={styles.partnersContent}>
        <h2 className={styles.partnersHeader}>{title}</h2>
        <p className={styles.partnersBody}>{body}</p>
      </div>
      <LogoSlider slides={companies} />
    </div>
  );
}
export default Partners;
