import React from "react";
import styles from "./index.module.scss";
import CompanyCard from "./CompanyCard";
import { useTranslation } from "react-i18next";

function NavbarMenu({
  dark,
  companies,
  handleTimeline,
  handleReverseTimeline,
}) {
  // STATES
  // STORE STATES
  // HOOKS
  const { t } = useTranslation();
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <div
      id="menu"
      onMouseEnter={handleTimeline}
      onMouseLeave={handleReverseTimeline}
      className={`${styles.navbarMenu}`}
    >
      <div className={styles.navbarMenuContent}>
        <h2 className={styles.navbarMenuContentTitle}>{t("our_companies")}</h2>
        <p className={styles.navbarMenuContentText}>{t("explore")}</p>
      </div>
      <div className={styles.cardContainer}>
        {companies &&
          companies.map((company, index) => (
            <CompanyCard company={company} key={index} />
          ))}
      </div>
    </div>
  );
}
export default NavbarMenu;
