import React from "react";
import styles from "./index.module.scss";
import { useForm } from "react-hook-form";
import TextInput from "./TextInput/TextInput";
import assets from "../../assets";

function HigForm({ fields, onSubmit, message }) {
  const defaultValues = {};
  for (const field of fields) {
    defaultValues[field.fieldName] = "";
  }

  const {
    watch,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
  });

  const watchingResume = watch("resume");
  return (
    <div>
      <form
        onSubmit={handleSubmit((data) => onSubmit(data, reset))}
        className={styles.form}
      >
        <div className={styles.inputsWrapper}>
          {fields.map((field) => (
            <TextInput
              key={field.fieldName}
              {...field}
              {...register(field.fieldName, field.validations)}
              watchingResume={watchingResume}
              error={errors[field.fieldName]?.message}
            />
          ))}
        </div>
        <div className="form-control">
          <button type="submit" className={styles.submitBtn}>
            <img
              className={styles.submitBtnIcon}
              loading="lazy"
              src={assets.icons.submitButtonIcon}
              alt="submit application"
            />
          </button>
        </div>
      </form>
      {message && (
        <p
          className={`${styles.message} ${
            message.success ? styles.success : styles.failure
          }`}
        >
          {message.text}
        </p>
      )}
    </div>
  );
}

export default HigForm;
