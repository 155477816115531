import React from "react";
import styles from "./index.module.scss";

function ArrowCircle({ animate, circleColor, arrowColor }) {
  // STATES
  // STORE STATES
  // HOOKS
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <svg
      className={styles.arrowCircle}
      overflow="visible"
      width="46"
      height="38"
      viewBox="0 0 46 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id={animate ? "arrow-circle" : undefined}
        d="M1.10396 19.8857H27.8904L24.7472 23.0289C24.3744 23.4016 24.3744 24.0061 24.7472 24.3788C24.9335 24.5652 25.1778 24.6584 25.4221 24.6584C25.6664 24.6584 25.9107 24.5652 26.0971 24.3788L30.8698 19.606C31.2426 19.2333 31.2426 18.6289 30.8698 18.2561L26.0971 13.4834C25.7243 13.1107 25.1199 13.1107 24.7472 13.4834C24.3744 13.8561 24.3744 14.4605 24.7472 14.8333L27.8904 17.9766H1.10396C0.576796 17.9766 0.149414 18.404 0.149414 18.9311C0.149414 19.4583 0.576796 19.8857 1.10396 19.8857Z"
        fill={arrowColor || "#000"}
      />
      <circle
        cx="26.8604"
        cy="18.9312"
        r="18.1807"
        stroke={circleColor || "#912934"}
      />
    </svg>
  );
}
export default ArrowCircle;
