import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LanguageSwitcher() {
  // VARIABLES
  const languages = ["ar", "en"];
  // STATES
  // STORE STATES
  // HOOKS
  const { i18n } = useTranslation();
  const { locale } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const showLang = () => {
    const currentIndex = languages.indexOf(locale);
    if (currentIndex === 0) {
      return languages[1];
    } else {
      return languages[0];
    }
  };
  // METHODS
  const changeLang = () => {
    const path = location.pathname
      .split("/")
      .filter((str, index) => index > 1)
      .join("/");
    i18n.changeLanguage(showLang());
    navigate(`/${showLang()}/${path}`);
  };
  // EFFECTS
  useEffect(() => {
    if (locale !== i18n.language) {
      i18n.changeLanguage(locale);
    }
  }, []);
  // RENDER
  return (
    <button className={styles.LanguageSwitcherButton} onClick={changeLang}>
      {showLang()}
    </button>
  );
}
export default LanguageSwitcher;
