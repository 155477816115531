export const getJobDetails = async (locale, id) => {
  const data = {
    job_id: `${id}`,
  };
  const res = await fetch(`${process.env.REACT_APP_BASE_URL}/job_detail`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": locale,
    },
  });
  return res.json();
};
