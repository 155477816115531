import React, { useState } from "react";
import styles from "./index.module.scss";
import HigForm from "../HigForm/HigForm";
import { submitContact } from "../../utils/submitContact";
import assets from "../../assets";
import { submitApplication } from "../../utils/submitApplication";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function FormSection({ content, formFields, page }) {
  const [message, setMessage] = useState(null);
  const { t } = useTranslation();
  const { jobId } = useParams();
  const onSubmit = async (data, reset) => {
    let status;
    if (page === "contact") {
      status = await submitContact(data);
    } else if (page === "job-details") {
      status = await submitApplication(data, jobId);
    } else {
      status = await submitApplication(data);
    }
    if (status.toString().startsWith("2")) {
      setMessage({
        success: true,
        text: t("form_success"),
      });
    } else {
      setMessage({
        success: false,
        text: t("form_failure"),
      });
    }
    reset();
    setTimeout(() => {
      setMessage(null);
    }, 3000);
  };
  return (
    <div id="form" className={styles.formSectionWrapper}>
      <div className={styles.col}>
        <h2>{content.title}</h2>
        <p>{content.description}</p>
      </div>
      <div className={styles.col}>
        <HigForm message={message} fields={formFields} onSubmit={onSubmit} />
      </div>
      <img
        loading="lazy"
        className={styles.formSectionImage}
        src={assets.icons.formImage}
        alt=""
      />
    </div>
  );
}

export default FormSection;
