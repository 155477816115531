import { useNavigate } from "react-router-dom";
import MoreAboutUs from "../../../components/MoreAboutUs";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
function CompanyCard({ company }) {
  // STATES
  const navigate = useNavigate();
  // STORE STATES
  // HOOKS
  const { t } = useTranslation();
  // METHODS
  // EFFECTS
  // RENDER
  const { title, body, link, logo, image } = company;
  return (
    <a href={link} target="_blank"

      className={styles.card}
    >
      <img
        loading="lazy"
        className={styles.cardLogo}
        src={logo}
        width={1000}
        height={1000}
        alt="company logo"
      />
      <h3 className={styles.cardHeader}>{title}</h3>
      <p className={styles.cardBody}>{body}</p>
      <MoreAboutUs text={t("find_more")} link={link} />
      <img
        loading="lazy"
        className={styles.cardImage}
        src={image}
        width={1000}
        height={1000}
        alt=""
      />
    </a>
  );
}
export default CompanyCard;
