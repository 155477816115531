import React from "react";
import styles from "./JobCategoryBadge.module.scss";
;
function JobCategoryBadge({ icon, label }) {
  return (
    <div className={styles.badge}>
      <img loading="lazy" src={icon} alt="label" />
      {label}
    </div>
  );
}

export default JobCategoryBadge;
