import React from "react";
import styles from "./VacancyCard.module.scss";
import ArrowCircle from "../ArrowCircle";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
function VacancyCard({ id, department, jobTitle, exp }) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className={styles.vacancyCard}
      onClick={() => {
        navigate(`/${i18n.language}/careers/${id}`);
      }}
    >
      <p className={styles.department}>{department}</p>
      <h3>{jobTitle}</h3>
      <div className={styles.expWrapper}>
        <p>{exp}</p>
        <ArrowCircle
          animate={false}
          circleColor={"#912934"}
          arrowColor={"#000000"}
        />
      </div>
    </div>
  );
}

export default VacancyCard;
