import React from "react";
import styles from "./index.module.scss";
import assets from "../../assets";
import { useTranslation } from "react-i18next";

function OurStory({ title, body }) {
  // STATES
  // STORE STATES
  // HOOKS
  const { t } = useTranslation();
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <section className={styles.ourStory}>
      <p className={styles.ourStoryText}>{t("our_story")}</p>
      <h2 className={styles.ourStoryHeader}>{title}</h2>
      <p className={styles.ourStoryBody}>{body}</p>
      <img
        loading="lazy"
        className={styles.ourStoryImage}
        src={assets.icons.ourStory}
        alt=""
      />
    </section>
  );
}
export default OurStory;
