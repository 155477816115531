import React, { useEffect, useState } from "react";
import { getHome } from "../../utils/getHome";
import NavbarContainer from "./NavbarContainer";
import styles from "./index.module.scss";
import { useParams } from "react-router-dom";

function Navbar({ dark, animate }) {
  const [companies, setCompanies] = useState(null);
  const { locale } = useParams();

  useEffect(() => {
    async function startFetch() {
      setCompanies(null);
      const res = await getHome(locale);
      setCompanies(res.our_companies);
    }
    startFetch();
  }, [locale]);

  // VARIABLES
  // STATES
  // STORE STATES
  // HOOKS
  // METHODS
  const backLayer = () => {
    if (animate && dark) {
      return styles.navbarDarkBacklayer;
    } else if (animate && !dark) {
      return styles.navbarBacklayer;
    } else if (!animate && dark) {
      return styles.navbarFullDarkBacklayer;
    } else {
      return styles.navbarFullBacklayer;
    }
  };
  // EFFECTS
  // RENDER
  return (
    <>
      <div id="animation" className={backLayer()}></div>
      <NavbarContainer dark={dark} animate={animate} companies={companies} />
    </>
  );
}
export default Navbar;
