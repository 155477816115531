import React from "react";
import { gsap } from "gsap";
import NavbarList from "../NavbarList";
import Logo from "../../Logo";
import NavbarMenu from "../NavbarMenu";
import { useEffect, useRef } from "react";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NavbarContainer({ dark, animate, companies }) {
  // STATES
  // STORE STATES
  // HOOKS
  const tlRef = useRef(null);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  // METHODS
  const textColor = () => {
    if (dark) {
      return "#fff";
    } else {
      if (!animate) {
        return "#A7A9AC";
      }
    }
    return undefined;
  };
  const handleTimeline = () => {
    tlRef.current.play();
  };
  const handleReverseTimeline = () => {
    tlRef.current.reverse();
  };
  // EFFECTS
  useEffect(() => {
    let timeline = null;
    if (animate) {
      timeline = gsap.timeline({ paused: true });
      timeline
        .to("#animation", { width: "100%", duration: 0.5 })
        .to(".text", { fill: "#A7A9AC", duration: 0.35, delay: -0.5 })
        .to(".logo", { fill: "#FFFFFF", duration: 0.35, delay: -0.5 })
        .to(".logo", { fill: "#912934", duration: 0.35, delay: -0.3 })
        .to("#menu", {
          height: "auto",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          duration: 0.75,
        });
    } else {
      timeline = gsap.timeline({ paused: true });
      timeline.to("#menu", {
        height: "auto",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        duration: 1,
      });
    }
    tlRef.current = timeline;
  }, []);
  // RENDER
  return (
    <>
      <div className={`${styles.navbar} ${dark ? styles.dark : undefined}`}>
        <a
          onClick={() => {
            navigate(`/${i18n.language}`);
          }}
        >
          <Logo textColor={textColor()} />
        </a>
        <NavbarList
          dark={dark}
          handleTimeline={handleTimeline}
          handleReverseTimeline={handleReverseTimeline}
        />
      </div>
      <NavbarMenu
        dark={dark}
        companies={companies}
        handleTimeline={handleTimeline}
        handleReverseTimeline={handleReverseTimeline}
      />
    </>
  );
}
export default NavbarContainer;
