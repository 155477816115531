import { useTranslation } from "react-i18next";
import WhyUsCard from "./WhyUsCard";
import styles from "./index.module.scss";

function WhyUs({ why }) {
  // STATES
  // STORE STATES
  // HOOKS
  const { t } = useTranslation();
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <div className={styles.whyUs}>
      <p className={styles.whyUsText}>{t("why_us")}</p>
      <div className={styles.whyUsContent}>
        <h2 className={styles.whyUsHeader}>{why.title}</h2>
        <p className={styles.whyUsBody}>{why.body}</p>
      </div>
      <div className={styles.whyUsOffering}>
        {why.attributes.map((attribute, index) => (
          <WhyUsCard attribute={attribute} key={index} />
        ))}
      </div>
    </div>
  );
}
export default WhyUs;
