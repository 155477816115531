import React, { useState } from "react";
import Slider from "react-slick";
import styles from "./index.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Arrow from "../Arrow";
import { useTranslation } from "react-i18next";

function Carousel({ slides }) {
  const [sliderRef, setSliderRef] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const { i18n } = useTranslation();

  const sliderSettings = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    speed: 500,
    rtl: i18n.language === "ar" ? true : false,
    autoplaySpeed: 3000,
    beforeChange: (oldIndex, newIndex) => {
      setActiveIndex(newIndex);
    },
  };

  return (
    <section className={styles.carousel}>
      <section className={styles.carouselControls}>
        <button
          className={`${styles.carouselControlsBtn} ${styles.prevBtn}`}
          onClick={sliderRef?.slickPrev}
        >
          <Arrow rotate={"180deg"} color={"#fff"} />
        </button>
        <p className={styles.carouselControlsNumber}>
          {activeIndex + 1} / {slides.length}
        </p>
        <button
          className={`${styles.carouselControlsBtn} ${styles.nextBtn}`}
          onClick={sliderRef?.slickNext}
        >
          <Arrow color={"#fff"} />
        </button>
      </section>
      <Slider ref={setSliderRef} {...sliderSettings}>
        {slides.map((slide, index) => (
          <section className={styles.carouselSlide} key={index}>
            {slide.image && (
              <>
                <img
                  width={100}
                  height={100}
                  className={styles.carouselSlideImage}
                  src={slide.image}
                  loading="eager"
                  alt=""
                />
                <div className={styles.carouselSlideImageOverlay}></div>
              </>
            )}
            {slide.title && slide.body && (
              <div className={styles.carouselSlideContent}>
                <h1 className={styles.carouselSlideTitle}>{slide.title}</h1>
                <p className={styles.carouselSlideDescription}>{slide.body}</p>
              </div>
            )}
          </section>
        ))}
      </Slider>
    </section>
  );
}
export default Carousel;
