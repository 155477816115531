import React from 'react'
import styles from './index.module.scss'
import ExploreButton from './ExploreButton'

function Explore({ text }) {
  // STATES
  // STORE STATES
  // HOOKS
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <div className={styles.explore}>
      <p className={styles.exploreText}>{text}</p>
      <ExploreButton/>
    </div>
  )
}
export default Explore