import React, { useEffect, useRef } from "react";
import styles from "./LoadingLogo.module.scss";
import { gsap } from "gsap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LoadingLogo({ props }) {
  // STATES
  // STORE STATES
  // HOOKS
  const tlRef = useRef(null);
  const { locale } = useParams();
  const { i18n } = useTranslation();
  // METHODS
  // EFFECTS
  useEffect(() => {
    const tl = gsap.timeline();
    if (locale === "en") {
      tl.to("#frontlayer", {
        x: "50%",
        y: "50%",
        repeat: -1,
        yoyoEase: true,
        duration: 1.5,
        delay: 0.1,
      }).to("#frontlayer", {
        x: "-15%",
        y: "-15%",
        yoyoEase: true,
        repeat: -1,
        duration: 0.75,
      });
    } else if (locale === "ar") {
      tl.to("#frontlayer", {
        x: "-90%",
        y: "-90%",
        repeat: -1,
        yoyoEase: true,
        duration: 1.5,
        delay: 0.1,
      }).to("#frontlayer", {
        x: "0%",
        y: "0%",
        yoyoEase: true,
        repeat: -1,
        duration: 0.75,
      });
    }
    tlRef.current = tl;
    return () => {
      tlRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (locale) i18n.changeLanguage(locale);
  }, [locale]);
  // RENDER
  return (
    <div className={styles.loading}>
      <div className={styles.logoContainer}>
        <div id="frontlayer" className={styles.logoFrontLayer}></div>
        <svg
          className={styles.logo}
          width="195"
          height="62"
          viewBox="0 0 195 62"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.8633 7.96725C13.818 5.82731 13.6366 4.37033 13.3191 3.55078C12.9563 2.73123 12.5482 2.18486 12.0493 1.82062C11.5504 1.41084 11.2783 0.910003 11.2783 0.227044L29.056 0.0449219L29.3735 28.365L51.0514 28.1373L50.7792 4.14268L63.659 4.00608L64.2032 53.0881L51.3688 60.6917L51.0514 30.5049L29.3735 30.7326L29.6002 53.4523L14.4076 61.1015L13.8633 7.96725Z"
            fill="#912934"
          />
          <path
            d="M51.0528 30.4594L27.1073 38.5638L27.0166 30.7325L51.0528 30.4594Z"
            fill="#912934"
          />
          <path
            d="M40.1226 10.3349L53.0023 10.1983L63.6598 4.0061L50.7801 4.1427L40.1226 10.3349Z"
            fill="#912934"
          />
          <path
            d="M0.576172 6.41921L18.3992 6.23708L29.0567 0.0449219L11.2337 0.227044L0.576172 6.41921Z"
            fill="#912934"
          />
          <path
            d="M84.8382 4.18811V18.8945H82.752V12.3381H74.362V18.8945H72.2759V4.18811H74.362V10.5169H82.752V4.18811H84.8382Z"
            fill="#A7A9AC"
          />
          <path
            d="M101.391 15.2521H93.5908L91.9581 18.94H89.7812L96.4025 4.23364H98.4887L105.155 18.94H102.978L101.391 15.2521ZM100.666 13.5674L97.491 6.37358L94.3164 13.5674H100.666Z"
            fill="#A7A9AC"
          />
          <path
            d="M122.798 14.9333C122.798 17.4375 120.893 18.8945 117.174 18.8945H110.19V4.18811H116.766C120.122 4.18811 122.027 5.59956 122.027 7.96715C122.027 9.56072 121.21 10.699 120.077 11.2454C121.709 11.7462 122.798 12.9755 122.798 14.9333ZM112.321 5.91827V10.6079H116.63C118.761 10.6079 119.986 9.78838 119.986 8.24033C119.986 6.69229 118.761 5.87274 116.63 5.87274H112.321V5.91827ZM120.711 14.7512C120.711 13.0666 119.442 12.2926 117.129 12.2926H112.321V17.1643H117.129C119.442 17.2099 120.711 16.4814 120.711 14.7512Z"
            fill="#A7A9AC"
          />
          <path
            d="M138.217 15.2521H130.416L128.784 18.94H126.652L133.274 4.23364H135.36L142.026 18.94H139.804L138.217 15.2521ZM137.491 13.5674L134.317 6.37358L131.142 13.5674H137.491Z"
            fill="#A7A9AC"
          />
          <path
            d="M145.654 17.2099L146.425 15.5708C147.514 16.5724 149.373 17.2554 151.278 17.2554C153.818 17.2554 154.906 16.2537 154.906 14.9789C154.906 11.3819 146.017 13.6585 146.017 8.1948C146.017 5.91827 147.786 4.00598 151.641 4.00598C153.364 4.00598 155.133 4.46129 156.357 5.32637L155.677 7.011C154.407 6.19145 152.956 5.82721 151.641 5.82721C149.146 5.82721 148.103 6.87441 148.103 8.14927C148.103 11.7462 156.992 9.51518 156.992 14.8878C156.992 17.1188 155.178 19.0766 151.323 19.0766C149.056 19.0766 146.833 18.3026 145.654 17.2099Z"
            fill="#A7A9AC"
          />
          <path
            d="M175.404 4.18811V18.8945H173.318V12.3381H164.928V18.8945H162.842V4.18811H164.928V10.5169H173.318V4.18811H175.404Z"
            fill="#A7A9AC"
          />
          <path
            d="M188.238 13.8406V18.94H186.152V13.7951L180.347 4.23364H182.569L187.241 11.9738L191.957 4.23364H194.043L188.238 13.8406Z"
            fill="#A7A9AC"
          />
          <path
            d="M72.1885 24.4495H75.0003V36.6517H72.1885V24.4495Z"
            fill="#A7A9AC"
          />
          <path
            d="M89.5128 24.4495V36.6517H87.1999L81.1229 29.2302V36.6517H78.3564V24.4495H80.6694L86.7464 31.8254V24.4495H89.5128Z"
            fill="#A7A9AC"
          />
          <path
            d="M104.569 24.4495L99.3084 36.6517H96.542L91.2812 24.4495H94.3198L98.0386 33.1913L101.803 24.4495H104.569Z"
            fill="#A7A9AC"
          />
          <path
            d="M115.499 34.3751V36.6517H106.066V24.4495H115.273V26.726H108.878V29.3668H114.547V31.5522H108.878V34.3751H115.499Z"
            fill="#A7A9AC"
          />
          <path
            d="M117.268 35.4225L118.22 33.2825C119.263 34.011 120.76 34.5574 122.256 34.5574C123.934 34.5574 124.614 34.011 124.614 33.237C124.614 30.9605 117.54 32.5085 117.54 28.001C117.54 25.9066 119.218 24.1764 122.664 24.1764C124.206 24.1764 125.748 24.5406 126.882 25.2691L126.02 27.4091C124.887 26.7716 123.707 26.4529 122.664 26.4529C120.986 26.4529 120.351 27.0903 120.351 27.8644C120.351 30.0954 127.426 28.5473 127.426 33.0549C127.426 35.1037 125.748 36.8339 122.256 36.8339C120.306 36.8339 118.356 36.2875 117.268 35.4225Z"
            fill="#A7A9AC"
          />
          <path
            d="M132.189 26.7262H128.289V24.4042H138.901V26.7262H135.001V36.6519H132.189V26.7262Z"
            fill="#A7A9AC"
          />
          <path
            d="M151.959 36.6514V29.321L148.377 35.3766H147.107L143.524 29.5031V36.697H140.894V24.4492H143.206L147.742 32.0528L152.231 24.4492H154.544L154.59 36.6514H151.959Z"
            fill="#A7A9AC"
          />
          <path
            d="M167.424 34.3749V36.6514H157.991V24.4492H167.198V26.7257H160.803V29.3665H166.472V31.552H160.803V34.3749H167.424Z"
            fill="#A7A9AC"
          />
          <path
            d="M181.347 24.4492V36.6514H179.034L172.957 29.2299V36.6514H170.19V24.4492H172.503L178.535 31.8707V24.4492H181.347Z"
            fill="#A7A9AC"
          />
          <path
            d="M187.242 26.7259H183.342V24.4038H193.954V26.7259H190.054V36.6515H187.242V26.7259Z"
            fill="#A7A9AC"
          />
          <path
            d="M81.2101 46.6227H83.7951V51.5855C82.4346 52.5872 80.6205 53.1336 78.8972 53.1336C75.0877 53.1336 72.2759 50.4928 72.2759 46.8048C72.2759 43.1168 75.0876 40.4761 78.9879 40.4761C81.1194 40.4761 82.8881 41.2046 84.0672 42.5705L82.2531 44.2551C81.3461 43.299 80.3484 42.8892 79.1239 42.8892C76.7656 42.8892 75.133 44.4828 75.133 46.8048C75.133 49.0814 76.7657 50.7205 79.0786 50.7205C79.8495 50.7205 80.5751 50.5839 81.2554 50.2196V46.6227H81.2101Z"
            fill="#A7A9AC"
          />
          <path
            d="M96.2658 52.9515L93.9075 49.5367H93.7715H91.3225V52.9515H88.5107V40.7037H93.7715C97.0368 40.7037 99.0776 42.3884 99.0776 45.1202C99.0776 46.9414 98.1706 48.3073 96.5833 48.9903L99.3043 52.9059H96.2658V52.9515ZM93.5901 43.0258H91.2772V47.3057H93.5901C95.3134 47.3057 96.1751 46.4861 96.1751 45.1657C96.1751 43.7998 95.3134 43.0258 93.5901 43.0258Z"
            fill="#A7A9AC"
          />
          <path
            d="M102.616 46.8048C102.616 43.1624 105.428 40.4761 109.283 40.4761C113.092 40.4761 115.949 43.1624 115.949 46.8048C115.949 50.4473 113.138 53.1336 109.283 53.1336C105.428 53.1336 102.616 50.4473 102.616 46.8048ZM113.047 46.8048C113.047 44.4828 111.414 42.8892 109.237 42.8892C107.061 42.8892 105.428 44.4828 105.428 46.8048C105.428 49.1269 107.061 50.7205 109.237 50.7205C111.414 50.7205 113.047 49.1269 113.047 46.8048Z"
            fill="#A7A9AC"
          />
          <path
            d="M120.031 47.5789V40.7493H122.843V47.4878C122.843 49.8099 123.84 50.766 125.564 50.766C127.287 50.766 128.285 49.8099 128.285 47.4878V40.7037H131.051V47.5333C131.051 51.1302 129.01 53.1336 125.518 53.1336C122.072 53.1336 120.031 51.1758 120.031 47.5789Z"
            fill="#A7A9AC"
          />
          <path
            d="M146.47 45.1657C146.47 47.8976 144.429 49.5822 141.164 49.5822H138.715V52.9515H135.903V40.7037H141.164C144.429 40.7037 146.47 42.3884 146.47 45.1657ZM143.613 45.1657C143.613 43.7998 142.751 43.0258 141.028 43.0258H138.715V47.2601H141.028C142.751 47.2601 143.613 46.4861 143.613 45.1657Z"
            fill="#A7A9AC"
          />
        </svg>
      </div>
    </div>
  );
}
export default LoadingLogo;
