import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import styles from "./index.module.scss";
import ArrowCircle from "../../../components/ArrowCircle";
import assets from "../../../assets";
import JobCategoryBadge from "../../../components/JobCategoryBadge/JobCategoryBadge";
import { getJobDetails } from "../../../utils/getJobDetails";
import FormSection from "../../../components/FormSection";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingLogo from "../../../components/LoadingLogo";

const fields = [
  {
    fieldName: "first_name",
    label: "first_name",
    type: "text",
    validations: {
      required: "required",
      minLength: { value: 2, message: "too_short" },
      maxLength: { value: 30, message: "exceed" },
    },
  },
  {
    fieldName: "last_name",
    label: "last_name",
    type: "text",
    validations: {
      required: "required",
      minLength: { value: 2, message: "too_short" },
      maxLength: { value: 30, message: "exceed" },
    },
  },
  {
    fieldName: "email",
    label: "email",
    type: "email",
    validations: {
      required: "required",
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: "invalid_email",
      },
      maxLength: { value: 100, message: "exceed" },
    },
  },
  {
    fieldName: "phone",
    label: "phone_number",
    type: "text",
    validations: {
      required: "required",
      pattern: { value: /^0\d{10}$/, message: "invalid_phone" },
    },
  },
  {
    fieldName: "address",
    label: "address",
    type: "text",
    validations: {
      required: false,
      maxLength: { value: 100, message: "exceed" },
    },
  },
  {
    fieldName: "country",
    label: "country",
    type: "text",
    validations: {
      required: false,
      maxLength: { value: 60, message: "exceed" },
    },
  },

  {
    fieldName: "years_of_experience",
    label: "years_of_experience",
    type: "number",
    validations: {
      required: "required",
      maxLength: { value: 20, message: "exceed" },
    },
  },
  {
    fieldName: "resume",
    label: "resume",
    type: "file",
    validations: {
      required: "required",
    },
    accept: ".doc, .docx, .pdf",
    fullWidth: true,
  },
];

export default function JobDetailsPage() {
  const [loading, setLoading] = useState(true);
  const [jobDetails, setJobDetails] = useState(null);
  const [data, setData] = useState(null);
  const { locale, jobId } = useParams();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    if (locale) i18n.changeLanguage(locale);
    async function startFetch() {
      setJobDetails(null);
      const res = await getJobDetails(locale, jobId);
      setJobDetails(res.job_detail);
      setData(res.company_info[0]);
      setLoading(false);
    }
    startFetch();
  }, [jobId, locale]);

  return (
    <>
      {loading && !jobDetails ? (
        <LoadingLogo />
      ) : (
        <>
          <Navbar animate={false} />
          <main className={styles.jobDetails}>
            <div className={styles.buttonWrapper}>
              <a
                href={`/${i18n.language}/careers#vacancies`}
                className={styles.backToVacancies}
              >
                <ArrowCircle
                  animate={false}
                  circleColor={"#912934"}
                  arrowColor={"#000000"}
                />
                {t("back_to_vacancies")}
              </a>
            </div>
            <div className={styles.jobDetailsImage}>
              <img
                loading="lazy"
                src={assets.images.jobDetailsHeader}
                alt="job details"
              />
            </div>

            <div className={styles.jobContent}>
              <div className={styles.jobHeader}>
                <p>{t("hiring")}</p>
                <h1>{jobDetails.title}</h1>
                <div className={styles.badges}>
                  <JobCategoryBadge
                    icon={assets.icons.timeIcon}
                    label={jobDetails.availability}
                  />
                  <JobCategoryBadge
                    icon={assets.icons.levelIcon}
                    label={jobDetails.level}
                  />
                </div>
                <a href="#form" className={styles.applyNowBtn}>
                  <ArrowCircle
                    animate={true}
                    circleColor={"#912934"}
                    arrowColor={"#000000"}
                  />
                  {t("apply_now")}
                </a>
              </div>
              <div className={styles.jobBody}>
                <div
                  dangerouslySetInnerHTML={{ __html: jobDetails.description }}
                ></div>
                <h5>{t("core")}</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: jobDetails.responsibilities,
                  }}
                ></div>
                <h5>{t("profile")}</h5>
                <div
                  dangerouslySetInnerHTML={{ __html: jobDetails.profile }}
                ></div>
                <h5>{t("benifits")}</h5>
                <div
                  dangerouslySetInnerHTML={{ __html: jobDetails.benifit }}
                ></div>
              </div>
            </div>
            <FormSection
              content={{
                title: t("jobDetailsPage.form_header"),
                description: t("jobDetailsPage.form_body"),
              }}
              formFields={fields}
              page={"job-details"}
            />
          </main>
          <Footer data={data} />
        </>
      )}
    </>
  );
}
