import React from "react";
import LogoTitle from "../LogoTitle";
import MoreAboutUs from "../MoreAboutUs";
import styles from "./index.module.scss";
import assets from "../../assets";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function AboutUs({ about }) {
  // STATES
  // STORE STATES
  // HOOKS
  const { locale } = useParams();
  const { t } = useTranslation();
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <div className={styles.aboutUs}>
      <img
        className={styles.aboutUsBackground}
        src={assets.icons.higBg}
        alt="decorative background"
        loading="lazy"
      />
      <div className={styles.aboutUsContent}>
        <p className={styles.aboutUsText}>{t("about_us")}</p>
        <LogoTitle title={about.title} />
        <p className={styles.aboutUsBody}>{about.body}</p>
        <MoreAboutUs text={t("more_about")} link={`/${locale}/about`} />
      </div>
      <img
        loading="lazy"
        className={styles.aboutUsImage}
        src={assets.images.aboutUs}
        alt="decorative"
      />
    </div>
  );
}
export default AboutUs;
