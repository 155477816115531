import React from 'react'

function Arrow({ width, height, color, rotate, disabled, animate }) {
  // STATES
  // STORE STATES
  // HOOKS
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <svg
      style={{
        transform: `rotate(${rotate})`,
        filter: `drop-shadow(1px 1px 10px #00000055)`,
        opacity: disabled && '0.5'
      }}
      id={animate ? 'arrow' : undefined}
      width={width || '27'}
      height={height || '17'}
      viewBox={`0 0 ${width || '27'} ${height || '17'}`}
      fill="none" xmlns = "http://www.w3.org/2000/svg" >
      <path d="M1.50972 9.65196H22.2891L18.3981 13.9498C17.9367 14.4594 17.9367 15.2858 18.3981 15.7955C18.6289 16.0503 18.9313 16.1778 19.2337 16.1778C19.5361 16.1778 19.8385 16.0503 20.0692 15.7954L25.9772 9.26963C26.4386 8.76001 26.4386 7.93359 25.9772 7.42388L20.0692 0.898085C19.6078 0.388464 18.8596 0.388464 18.3981 0.898085C17.9367 1.40771 17.9367 2.23413 18.3981 2.74384L22.2891 7.04164H1.50972C0.857166 7.04164 0.328125 7.626 0.328125 8.3468C0.328125 9.06759 0.857166 9.65196 1.50972 9.65196Z" fill={color || 'black'} />
    </svg>
  )
}
export default Arrow