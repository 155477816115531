import styles from "./index.module.scss";
import Copyright from "./Copyrights";
import MoreAboutUs from "../MoreAboutUs";
import Logo from "../Logo";
import assets from "../../assets";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const links = [
  {
    title: "company",
    links: [
      {
        link: "/about",
        text: "about",
      },
      {
        link: "/careers",
        text: "careers",
      },
      {
        link: "/contact",
        text: "contact",
      },
    ],
  }];
const companies = [
  {
    title: "companies",
    links: [
      {
        link: "https://steel.alhabashy.com/en",
        text: "steel",
      },
      {
        link: "https://contracting.alhabashy.com/en",
        text: "constructions",
      },
    ],
  }
];



function Footer({ data }) {
  // STATES
  // STORE STATES
  // HOOKS
  const { locale } = useParams();
  const { t } = useTranslation();

  const socialMedia = [
    {
      type: "facebook_dark",
      link: data.facebook,
    },
    {
      type: "linkedin_dark",
      link: data.linkedin,
    },
    {
      type: "instagram_dark",
      link: data.instgram,
    },
  ];
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <footer className={styles.footer}>
      <div className={styles.contactUs}>
        <p className={styles.contactUsText}>{t("contact_us")}</p>
        <h2 className={styles.contactUsHeader}>{t("discover")}</h2>
        <MoreAboutUs text={t("get_in_touch")} link={`/${locale}/contact`} />
      </div>
      <hr className={styles.footerLine} />
      <div className={styles.footerLinks}>
        <div className={styles.footerLogo}>
          <Logo textColor={"#A7A9AC"} />
          <p className={styles.address}>{data.address}</p>
        </div>
        <div className={styles.linksContainer}>
          {links.map((container, index) => {
            return (
              <div className={styles.linksFlex} key={index}>
                <h3 className={styles.linksHeader}>{t(container.title)}</h3>
                {container.links && (
                  <ul className={styles.link}>
                    {container.links.map((link, index) => {
                      return (
                        <a key={index} href={`/${locale}${link.link}`}>
                          {t(link.text)}
                        </a>
                      );
                    })}
                  </ul>
                )}
              </div>
            );
          })}
          {companies.map((container, index) => {
            return (
              <div className={styles.linksFlex} key={index}>
                <h3 className={styles.linksHeader}>{t(container.title)}</h3>
                {container.links && (
                  <ul className={styles.link}>
                    {container.links.map((link, index) => {
                      return (
                        <a key={index} href={link.link} target="_blank">
                          {t(link.text)}
                        </a>
                      );
                    })}
                  </ul>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles.getInTouch}>
          <h3 className={styles.linksHeader}>{t("get_in_touch")}</h3>
          <div className={styles.contactPhone}>
            <p className={styles.phone}>{data.phone}</p>

            {data.phone2 &&
              <>
                <span> - </span>
                <p className={styles.phone}>{data.phone2}</p>
              </>}
          </div>

          <div className={styles.socialMedia}>
            {socialMedia.map((social, index) => {
              return (
                <a href={social.link} key={index}>
                  <img
                    loading="lazy"
                    alt={social.type}
                    src={assets.icons[social.type]}
                  />
                </a>
              );
            })}
          </div>
          <p className={styles.email}>{data.email}</p>
        </div>
      </div>
      <hr className={styles.footerLine} />
      <Copyright />
    </footer>
  );
}
export default Footer;
