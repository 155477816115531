import React from "react";
import styles from "./index.module.scss";
import NumberLabel from "../NumberLabel";
import { useTranslation } from "react-i18next";

function Information({ info }) {
  const {
    years_of_experience: years,
    projects_completed: projects,
    award_gained: awards,
    happy_customers: customers,
  } = info;
  // STATES
  // STORE STATES
  // HOOKS
  const { t } = useTranslation();
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <div className={styles.info}>
      <NumberLabel
        number={years}
        paddingInline={"0 3rem"}
        hasRightBorder={true}
        fontSize={"18rem"}
        text={t("homePage.years_experience")}
      />
      <div className={styles.infoNumbersContainer}>
        <NumberLabel
          moreThan={true}
          number={projects}
          fontSize={"7rem"}
          text={t("projects_completed")}
        />
        <NumberLabel
          moreThan={true}
          number={customers}
          fontSize={"7rem"}
          text={t("happy_customers")}
        />
        <NumberLabel
          number={awards}
          fontSize={"7rem"}
          text={t("award_gained")}
        />
      </div>
    </div>
  );
}
export default Information;
