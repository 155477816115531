import React from "react";
import styles from "./index.module.scss";

function NumberLabel({
  moreThan,
  number,
  text,
  fontSize,
  paddingInline,
  hasRightBorder,
}) {
  // STATES
  // STORE STATES
  // HOOKS
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <p
      style={{ paddingInline }}
      className={`${styles.numberLabel} ${
        hasRightBorder && styles.rightBorder
      }`}
    >
      <span
        style={{ fontSize, lineHeight: fontSize }}
        className={`${styles.number}`}
      >
        {moreThan && "+"} {number}
      </span>
      {text}
    </p>
  );
}
export default NumberLabel;
