import logo from "./logo.svg";
import facebook from "./facebook.svg";
import facebook_dark from "./facebook_dark.svg";
import twitter from "./twitter.svg";
import twitter_dark from "./twitter_dark.svg";
import linkedin from "./linkedin.svg";
import linkedin_dark from "./linkedin_dark.svg";
import instagram from "./instagram.svg";
import instagram_dark from "./instagram_dark.svg";
import playIcon from "./playIcon.svg";
import mission from "./mission.svg";
import vision from "./vision.svg";
import philosophy from "./philosophy.svg";
import higIcon from "./hig-careers.svg";
import showMoreArrow from "./show-more.svg";
import attachIcon from "./attach.svg";
import submitButtonIcon from "./submit.svg";
import timeIcon from "./time.svg";
import levelIcon from "./level.svg";
import pdfIcon from "./pdf-icon.svg";
import textIcon from "./txt-icon.svg";
import formImage from "./formImage.svg";
import ourStory from "./ourStory.svg";
import higBg from "./higBg.svg";

const icons = {
  logo,
  facebook,
  facebook_dark,
  instagram,
  instagram_dark,
  linkedin,
  linkedin_dark,
  twitter,
  twitter_dark,
  playIcon,
  mission,
  vision,
  philosophy,
  higIcon,
  showMoreArrow,
  attachIcon,
  submitButtonIcon,
  timeIcon,
  levelIcon,
  pdfIcon,
  textIcon,
  formImage,
  ourStory,
  higBg,
};

export default icons;
