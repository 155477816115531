import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/Home";
import AboutPage from "./pages/About";
import CareersPage from "./pages/Careers";
import JobDetailsPage from "./pages/Careers/JobDetails";
import ContactPage from "./pages/Contact";
import { useTranslation } from "react-i18next";
import NotFound from "./pages/NotFound";
import { HelmetProvider } from "react-helmet-async";
import SEO from "./components/SEO";

function App() {
  const { i18n } = useTranslation();
  document.documentElement.setAttribute("lang", i18n.language);
  document.body.dir = i18n.language === "ar" ? "rtl" : "ltr";

  return (
    <HelmetProvider>
      <SEO title={"HIG"} />
      <Routes>
        <Route path="/" element={<Navigate to={"/en"} />} exact />
        <Route path="/:locale" element={<HomePage />} exact />
        <Route path="/:locale/about" element={<AboutPage />} exact />
        <Route path="/:locale/careers" element={<CareersPage />} exact />
        <Route
          path="/:locale/careers/:jobId"
          element={<JobDetailsPage />}
          exact
        />
        <Route path="/:locale/contact" element={<ContactPage />} exact />
        <Route path="/:locale/*" element={<NotFound />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;
