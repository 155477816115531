import React, { useEffect, useRef } from "react";
import styles from "./index.module.scss";
import Arrow from "../../Arrow";
import { gsap } from "gsap";

function ExploreButton({ props }) {
  // STATES
  // STORE STATES
  // HOOKS
  const tlRef = useRef(null);
  // METHODS
  // EFFECTS
  useEffect(() => {
    const tl = gsap.timeline();
    tl.to("#arrow", { y: 10, repeat: -1, yoyoEase: true, duration: 0.5 });
    tlRef.current = tl;
  }, []);
  // RENDER
  return (
    <a href="#explore" className={styles.exploreButton}>
      <Arrow color={"#912934"} rotate={"90deg"} animate={true} />
    </a>
  );
}
export default ExploreButton;
