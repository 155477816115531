import styles from "./index.module.scss";
import LanguageSwitcher from "../../LanguageSwitcher";
import HamburgerMenu from "../HamburgerMenu";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NavbarList({ dark, handleTimeline, handleReverseTimeline }) {
  // VARIABLES
  const tabs = [
    {
      title: "about",
      link: "/about",
    },
    {
      title: "companies",
      link: "/companies",
    },
    {
      title: "careers",
      link: "/careers",
    },
    {
      title: "contact",
      link: "/contact",
    },
  ];

  // STATES
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  // STORE STATES
  // HOOKS
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <>
      <HamburgerMenu />
      <ul className={`${styles.navbarList} ${dark ? styles.dark : undefined}`}>
        {tabs.map((tab, index) => (
          <li
            key={index}
            onMouseEnter={() => {
              if (index === 1) {
                handleTimeline();
              }
            }}
            onMouseLeave={(e) => {
              if (index === 1 && e.relatedTarget.id !== "menu") {
                handleReverseTimeline();
              }
            }}
            className={`${styles.navbarListItem} ${
              location.pathname.split("/")[2] === tab.title
                ? styles.active
                : undefined
            }`}
          >
            {index !== 1 && (
              <a
                onClick={() => {
                  navigate(`/${i18n.language}${tab.link}`);
                }}
              >
                {t(tab.title)}
              </a>
            )}
            {index === 1 && <p>{t(tab.title)}</p>}
          </li>
        ))}
        <LanguageSwitcher />
      </ul>
    </>
  );
}
export default NavbarList;
