import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

function Copyright({ props }) {
  // STATES
  // STORE STATES
  // HOOKS
  const { t } = useTranslation();
  // METHODS
  // EFFECTS
  // RENDER
  return <p className={styles.copyright}>{t("copyrights")}</p>;
}
export default Copyright;
