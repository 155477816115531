import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { getAbout } from "../../utils/getAbout";
import Video from "../../components/Video";
import OurStory from "../../components/OurStory";
import OurApproach from "../../components/OurApproach";
import Criteria from "../../components/Criteria";
import PageInformations from "../../components/PageInformations/PageInformations";
import { useParams } from "react-router-dom";
import LoadingLogo from "../../components/LoadingLogo";
import { useTranslation } from "react-i18next";

export default function AboutPage() {
  const { locale } = useParams();
  const [about, setAbout] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
  useEffect(() => {
    setLoading(true);
    if (locale) i18n.changeLanguage(locale);
    async function startFetch() {
      setAbout(null);
      const res = await getAbout(locale);
      setAbout(res.about);
      console.log("res about", res.about);
      setData(res.company_info[0]);
      setLoading(false);
    }
    startFetch();
  }, [locale]);
  return (
    <>
      {loading && !about ? (
        <LoadingLogo />
      ) : (
        <>
          <Navbar animate={false} />
          <main className={styles.aboutUs}>
            <PageInformations title={about.title} body={about.body} />
            <Video src={about.video} />
            <OurStory title={about.story_title} body={about.story_body} />
            <Criteria
              mission={about.mission}
              vision={about.vision}
              philosophy={about.philosophy}
            />
            <OurApproach approaches={about.approaches} />
          </main>
          <Footer data={data} />
        </>
      )}
    </>
  );
}
