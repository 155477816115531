import styles from "./index.module.scss";

function WhyUsCard({ attribute }) {
  // STATES
  // STORE STATES
  // HOOKS
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <div className={styles.card}>
      <div className={styles.cardIcon}>
        <img
          loading="lazy"
          className={styles.cardImage}
          width={50}
          height={50}
          src={attribute.icon}
          alt="icon"
        />
      </div>
      <h3 className={styles.cardHeader}>{attribute.title}</h3>
      <p className={styles.cardBody}>{attribute.body}</p>
    </div>
  );
}
export default WhyUsCard;
