export const submitApplication = async (data, job_id) => {
  const formData = new FormData();

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (key !== "resume") formData.append(key, data[key]);
      else {
        formData.append(key, data[key][0]);
      }
    }
  }

  if (job_id) {
    formData.append("job", job_id);
  }

  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}/candidate_job_application`,
    {
      method: "POST",
      body: formData,
    }
  );
  return res.json();
};
