import React, { useRef, useState } from "react";
import styles from "./Vacancies.module.scss";
import VacancyCard from "../VacancyCard/VacancyCard";
import assets from "../../assets";
import { getJobs } from "../../utils/getCareers";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Vacancies({ jobsProp }) {
  const page = useRef(1);
  const { locale } = useParams();
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [jobs, setJob] = useState(jobsProp);
  const { t } = useTranslation();
  const handleShowMore = async () => {
    if (showMore) {
      setLoading(true);
      setShowMore(false);
      page.current++;
      const res = await getJobs(locale, page.current);
      setJob((prev) => [...prev, ...res.results]);
      if (res.next) setShowMore(true);
      setLoading(false);
    }
  };

  return (
    <div className={styles.vacancies}>
      <div className={styles.vacanciesGrid}>
        {jobs &&
          jobs.map((job, index) => {
            return (
              <VacancyCard
                department={job.department}
                jobTitle={job.title}
                exp={`${job.years_of_experience} ${t("years_experience")}`}
                key={index}
                id={job.id}
              />
            );
          })}
        {loading && (
          <div className="">
            <Skeleton className={styles.skeleton} height={220} />
          </div>
        )}
      </div>
      {/* {loading && */}
      {/* // } */}
      {showMore && (
        <button onClick={handleShowMore}>
          {t("show_more")}{" "}
          <span>
            <img
              loading="lazy"
              src={assets.icons.showMoreArrow}
              alt="show more vacancies"
            />
          </span>
        </button>
      )}
    </div>
  );
}

export default Vacancies;
