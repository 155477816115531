import React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

function OurApproach({ approaches }) {
  // VARIABLES
  // STATES
  // STORE STATES
  // HOOKS
  const { t } = useTranslation();
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <section className={styles.ourApproach}>
      <h2 className={styles.ourApproachHeader}>{t("our_approach")}</h2>
      <div className={styles.ourApproachContainer}>
        {approaches.map((approach, index) => (
          <div key={index} className={styles.ourApproachContentContainer}>
            <p className={styles.ourApproachNo}>{`0${index + 1}`}</p>
            <div className={styles.ourApproachContent}>
              <h3 className={styles.ourApproachTitle}>{approach.title}</h3>
              <p className={styles.ourApproachBody}>{approach.body}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
export default OurApproach;
