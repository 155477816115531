import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import assets from "../../assets";
import ArrowCircle from "../../components/ArrowCircle";
import PageInformations from "../../components/PageInformations/PageInformations";
import Vacancies from "../../components/Vacancies/Vacancies";
import { getCareers, getJobs } from "../../utils/getCareers";
import FormSection from "../../components/FormSection";
import { useLocation, useParams } from "react-router-dom";
import LoadingLogo from "../../components/LoadingLogo";
import { useTranslation } from "react-i18next";

const fields = [
  {
    fieldName: "first_name",
    label: "first_name",
    type: "text",
    validations: {
      required: "required",
      minLength: { value: 2, message: "too_short" },
      maxLength: { value: 30, message: "exceed" },
    },
  },
  {
    fieldName: "last_name",
    label: "last_name",
    type: "text",
    validations: {
      required: "required",
      minLength: { value: 2, message: "too_short" },
      maxLength: { value: 30, message: "exceed" },
    },
  },
  {
    fieldName: "email",
    label: "email",
    type: "email",
    validations: {
      required: "required",
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: "invalid_email",
      },
      maxLength: { value: 100, message: "exceed" },
    },
  },
  {
    fieldName: "phone",
    label: "phone_number",
    type: "text",
    validations: {
      required: "required",
      pattern: { value: /^0\d{10}$/, message: "invalid_phone" },
    },
  },
  {
    fieldName: "address",
    label: "address",
    type: "text",
    validations: {
      required: false,
      maxLength: { value: 100, message: "exceed" },
    },
  },
  {
    fieldName: "country",
    label: "country",
    type: "text",
    validations: {
      required: false,
      maxLength: { value: 60, message: "exceed" },
    },
  },
  {
    fieldName: "role_interested",
    label: "role_interested",
    type: "text",
    validations: {
      required: false,
      maxLength: { value: 100, message: "exceed" },
    },
  },
  {
    fieldName: "years_of_experience",
    label: "years_of_experience",
    type: "number",
    validations: {
      required: "required",
      maxLength: { value: 20, message: "exceed" },
    },
  },
  {
    fieldName: "resume",
    label: "resume",
    type: "file",
    validations: {
      required: "required",
    },
    accept: ".doc, .docx, .pdf",
    fullWidth: true,
  },
];

function CareersPage() {
  const [careers, setCareers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState(null);
  const vacanciesRef = useRef(null);
  const { locale } = useParams();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLoading(true);
    if (locale) i18n.changeLanguage(locale);
    async function startFetch() {
      setCareers(null);
      setJobs(null);
      const careersData = await getCareers(locale);
      const jobsData = await getJobs(locale, 1);
      setCareers(careersData);
      setJobs(jobsData.results);
      setLoading(false);
    }
    startFetch();
  }, [locale]);

  useEffect(() => {
    if (location.hash === "#vacancies" && vacanciesRef.current) {
      vacanciesRef.current.scrollIntoView(true);
    }
  }, [jobs]);

  return (
    <>
      {loading && !careers && !jobs ? (
        <LoadingLogo />
      ) : (
        <>
          <Navbar animate={false} />
          <main className={styles.careers}>
            <PageInformations
              title={careers.career_page.page_title}
              body={careers.career_page.page_body}
            />
            <div className={styles.careersImage}>
              <img
                loading="lazy"
                src={assets.images.careersImg}
                alt="Join out team"
              />
            </div>
            {jobs.length > 0 && (
              <>
                <div className={styles.viewVacancies}>
                  <div className={styles.higLogo}>
                    <img
                      loading="lazy"
                      src={assets.icons.higIcon}
                      alt="hig logo"
                    />
                  </div>
                  <p>{careers.career_page.main_page_header}</p>
                  <a href={"#vacancies"}>
                    <span>
                      <ArrowCircle
                        animate={true}
                        circleColor={"#912934"}
                        arrowColor={"#000000"}
                      />
                    </span>
                    {t("view_vacancies")}
                  </a>
                </div>
                <PageInformations
                  title={careers.career_page.vacancy_header}
                  body={careers.career_page.vacancy_body}
                  subTitle={t("vacancies")}
                />
                <div ref={vacanciesRef} id="vacancies">
                  {jobs && <Vacancies jobsProp={jobs} />}
                </div>
              </>
            )}
            <FormSection
              page={"careers"}
              content={{
                title: t("careersPage.form_header"),
                description: t("careersPage.form_body"),
              }}
              formFields={fields}
            />
          </main>
          <Footer data={careers.company_info[0]} />
        </>
      )}
    </>
  );
}

export default CareersPage;
