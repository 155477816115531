import React from "react";
import styles from "./PageInformations.module.scss";

function PageInformations({ title, body, subTitle }) {
  return (
    <div className={styles.pageInformations}>
      {subTitle ? <span className={styles.subtitle}>{subTitle}</span> : null}
      <div className={styles.pageInformationsContent}>
        <h2 className={styles.header}>{title}</h2>
        <p className={styles.body}>{body}</p>
      </div>
    </div>
  );
}

export default PageInformations;
