import React from "react";
import styles from "./index.module.scss";
import assets from "../../assets";
import { useTranslation } from "react-i18next";

function Criteria({ mission, vision, philosophy }) {
  // STATES
  // STORE STATES
  // HOOKS
  const { t } = useTranslation();
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <div className={styles.criteria}>
      <section className={styles.mission}>
        <div className={styles.missionList}>
          <img
            loading="lazy"
            className={styles.missionIcon}
            src={assets.icons.mission}
            alt="our mission"
          />
        </div>
        <div className={styles.missionContent}>
          <h2 className={styles.missionTitle}>{t("our_mission")}</h2>
          <p className={styles.missionBody}>{mission}</p>
        </div>
      </section>
      <section className={styles.vision}>
        <div className={styles.visionList}>
          <img
            loading="lazy"
            className={styles.visionIcon}
            src={assets.icons.vision}
            alt="our vision"
          />
        </div>
        <div className={styles.visionContent}>
          <h2 className={styles.visionTitle}>{t("our_vision")}</h2>
          <p className={styles.visionBody}>{vision}</p>
        </div>
      </section>
      <section className={styles.philosophy}>
        <div className={styles.philosophyList}>
          <img
            loading="lazy"
            className={styles.philosophyIcon}
            src={assets.icons.philosophy}
            alt="our philosophy"
          />
        </div>
        <div className={styles.philosophyContent}>
          <h2 className={styles.philosophyTitle}>{t("our_philosophy")}</h2>
          <p className={styles.philosophyBody}>{philosophy}</p>
        </div>
      </section>
    </div>
  );
}
export default Criteria;
