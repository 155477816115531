import assets from "../../assets";
import React from "react";
import styles from "./index.module.scss";

function LogoTitle({ title }) {
  // STATES
  // STORE STATES
  // HOOKS
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <div className={styles.container}>
      <img loading="lazy" src={assets.icons.logo} alt="Logo" />
      <h2 className={styles.title}>{title}</h2>
    </div>
  );
}
export default LogoTitle;
